import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import OrderSuccess from '@lp-root/src/modules/order-success/order-success.component';
import { PageProps } from '@lp-src/utils/local-types';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import * as React from 'react';
import CleanLayout from '@lp-root/src/components/org.layout/clean-layout.component';
import { useQueryParams } from '@web/atomic/obj.custom-hooks/query-params';
import { Body } from '@web/atomic';
import WhatsappFAB from '@web/atomic/atm.whatsapp-fab/whatsapp-fab.component';

interface OnboardingPageQueryParams {
  user_coupon: string;
  user_email: string;
  user_name: string;
  utm_medium: string;
  utm_source: string;
}

const OnboardingPage: React.FunctionComponent<PageProps> = (props) => {
  const queryParams = useQueryParams<OnboardingPageQueryParams>();
  const socialMedia = {
    canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
    title: 'Prazer, Sônia',
    description: 'Conheça a Sônia, nossa coach digital que te acompanhará durante todo o programa',
  };

  return (
    <CleanLayout>
      <SEO socialMedia={socialMedia} />
      <OrderSuccess
        code={queryParams.user_coupon}
        headline={'Começa agora o fim dos seus problemas com o sono!'}
        share={{
          url: socialMedia.canonicalUrl,
          title: socialMedia.title,
          text: socialMedia.description,
        }}
      >
        {queryParams.user_coupon ? (
          <Body>
            Você possui benefícios no nosso programa. Para ativá-los:
            <ol>
              {queryParams.user_email && (
                <li>
                  Faça o cadastro usando seu e-mail <strong>{queryParams.user_email}</strong>
                </li>
              )}
              <li>Utilize o seu código quando lhe for pedido</li>
            </ol>
          </Body>
        ) : null}
      </OrderSuccess>
      <WhatsappFAB kind="support" />
    </CleanLayout>
  );
};

export default OnboardingPage;

export const query = graphql`
  query OnboardingQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
